<template>
  <div class="d-flex justify-center">
    <v-btn
      v-for="index in buttonsProps.delimitersAmount"
      :key="index"
      :color="activeColor(index - 1)"
      :class="[
        { 'mr-4': buttonsProps.delimitersAmount !== index },
        'rounded-sm',
      ]"
      min-width="32px"
      max-height="6px"
      @click="changeSlide(index - 1)"
    />
  </div>
</template>

<script setup lang="ts">
const scssVariables = useScssVariables();
const buttonsProps = defineProps({
  activeDelimiter: {
    type: Number,
    default: null,
  },
  delimitersAmount: {
    type: Number,
    default: null,
  },
  changeSlide: {
    type: Function,
    default: () => ({}),
  },
});
const activeColor = (index: number) => index === buttonsProps.activeDelimiter
  ? scssVariables.jColorPrimary
  : scssVariables.jTextSecondary;
</script>
